import React from 'react';

const Hero = () => {
    const handleScroll = () => {
        document.getElementById('our-story-section').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="bg-yellow-500 flex flex-col md:flex-row items-center justify-center text-center h-screen w-screen snap-start">
            <div className="w-full md:w-1/4 p-4 text-4xl md:text-6xl lg:text-8xl font-bold hero-buttons-animation-fadeIn transform transition-transform duration-300 hover:scale-110 hover:rotate-6">
                <a href="/order-now" className="">
                    <span className="underline">
                        Order Now
                    </span>
                </a>
            </div>
            <div className="flex flex-1 flex-col p-4 mx-4 hero-titles-animation-fadeIn justify-center">
                {/* Circular image here */}
                <img
                    src="/logo.png"
                    alt="Description of Image"
                    className="w-32 h-32 md:w-48 md:h-48 lg:w-64 lg:h-64 rounded-full mx-auto mb-8 transform transition-transform duration-300 hover:scale-110 hover:rotate-12"
                />
                <div className="relative flex justify-center transform transition-transform duration-300 group hover:scale-110 hover:rotate-3">
                    <h1 className="text-4xl md:text-6xl lg:text-8xl font-bold group-hover:text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-red-500 bg-right bg-no-repeat bg-[length:200%_100%] group-hover:animate-[text-fill-left-to-right_1s_ease-in-out_forwards] z-10">
                        Stormhouse Pizza
                    </h1>
                </div>
                <h2 className="mt-12 text-2xl md:text-4xl lg:text-6xl font-light transform transition-transform duration-300 hover:scale-110 hover:rotate-9">
                    Stone baked oven pizzas
                </h2>
                <div className="mt-8 text-lg md:text-xl lg:text-2xl space-y-4">
                    <div className="flex flex-col space-y-1">
                        <div className="flex items-center justify-center space-x-2">
                            <p className="transform transition-transform duration-300 hover:scale-110 inline-block">
                                <a href="https://www.google.com/maps/search/?api=1&query=Quigleys+Kilmore" className="underline">Quigleys Kilmore</a>
                            </p>
                            <span>:</span>
                            <p className="transform transition-transform duration-300 hover:scale-110 inline-block">
                                <a href="tel:1234567890" className="underline">(123) 456-7890</a>
                            </p>
                        </div>
                        <p className='text-sm'>Wed-Thurs 3pm-9:45pm</p>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <div className="flex items-center justify-center space-x-2">
                            <p className="transform transition-transform duration-300 hover:scale-110 inline-block">
                                <a href="https://www.google.com/maps/search/?api=1&query=Kilmore+Quay" className="underline">Kilmore Quay</a>
                            </p>
                            <span>:</span>
                            <p className="transform transition-transform duration-300 hover:scale-110 inline-block">
                                <a href="tel:0987654321" className="underline">(098) 765-4321</a>
                            </p>
                        </div>
                        <p className='text-sm'>Wed-Thurs 3pm-9:45pm</p>
                    </div>
                </div>
            </div>
            <div
                className="w-full md:w-1/4 p-4 text-4xl md:text-6xl lg:text-8xl font-bold cursor-pointer hero-buttons-animation-fadeIn transform transition-transform duration-300 hover:scale-110 hover:-rotate-6"
                onClick={handleScroll}
            >
                <span className="underline">
                    Our Story
                </span>
            </div>
        </div>
    );
};

export default Hero;
