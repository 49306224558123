import React from 'react';

const StoryPage = ({ bgColor, text }) => {
    return (
        <section className={`h-screen snap-center ${bgColor} shrink-0 flex items-center justify-center`}>
            <h1 className="text-white text-3xl">{text}</h1>
        </section>
    );
};

export default StoryPage;
