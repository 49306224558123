import React from 'react';
import Hero from './Hero';
import StoryPage from './StoryPage';

const ScrollSnapBg = () => {
    return (
        <div className=" font-bebasneue-bold h-screen overflow-y-scroll snap-y scroll-snap-type-y snap-mandatory">
            <Hero />
            <StoryPage bgColor="bg-red-500" text="Section 1" />
            <StoryPage bgColor="bg-blue-500" text="Section 2" />
            <StoryPage bgColor="bg-green-500" text="Section 3" />
            <StoryPage bgColor="bg-yellow-500" text="Section 4" />
        </div>
    );
};

export default ScrollSnapBg;
