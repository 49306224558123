import React from 'react';
import ScrollSnapBg from './components/ScrollSnapBg';
import Navbar from './components/Navbar';

const App = () => {
    return (
        <div>
            <Navbar />
            <ScrollSnapBg />
        </div>
    );
};

export default App;
