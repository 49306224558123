import React from 'react';

const Navbar = () => {
  return (
    <nav className="fixed top-0 left-0 w-full flex justify-end items-center px-4 py-2 bg-transparent z-50">
      <div className="flex-shrink-0">
        <a href="/" className="block">
          <img
            src="/navbar-logo.png"
            alt="Logo"
            className="h-12 w-12 md:h-16 md:w-16 lg:h-20 lg:w-20 transition-transform duration-500 transform hover:rotate-360 active:rotate-360"
          />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
